import { EmbedHologram } from "@/lib/hologramProps"
import { twMerge } from "tailwind-merge"
import AddHologramToGoButton from "../AddHologramToGoButton"
import HologramCastButton from "../HologramCastButton"
import { useCastStore } from "@/hooks/useCastStore"
import BanHologramButton from "../BanHologramButton"
import HologramDropDown from "../HologramDropDown"
import { useState } from "react"
import FlagHologramPopup, { FlagHologram } from "../moderation/FlagHologramPopup"
import { Privacy } from "@prisma/client"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid"
import CheckBox from "./SelectButton"
import PreviewCardFooter from "./PreviewCardFooter"
import ShareHologramPopup from "../ShareHologramPopup"
import DownloadPopup from "../DownloadPopup"
import DeleteHologramPopup from "../DeleteHologramPopup"
import HologramSettingsPopup from "../HologramSettingsPopup"

interface OverlayProps {
	hologram: EmbedHologram
	playlistItemID?: number
	selected: boolean
	selectable: boolean
	onSelected?: () => void
}

export default function Overlay(props: OverlayProps) {
	const { hologram, playlistItemID, selectable, selected, onSelected } = props
	const { id, user } = hologram
	const castHologram = useCastStore((store) => store.hologram)
	const [openSettings, setOpenSettings] = useState<boolean>(false)
	const [downloadPopup, setDownloadPopup] = useState<EmbedHologram | null>(null)
	const [sharePopup, setSharePopup] = useState<EmbedHologram | null>(null)
	const [deletePopup, setDeletePopup] = useState<number[] | null>(null)
	const [flagPopup, setFlagPopup] = useState<FlagHologram | null>(null)
	const [privacy, setPrivacy] = useState<Privacy>(hologram.privacy)

	const onShareClose = () => {
		setSharePopup(null)
	}

	function onDownloadClose() {
		setDownloadPopup(null)
	}

	return (
		<div className={twMerge("absolute z-20 h-full w-full")}>
			<div className={twMerge("absolute right-3 top-3 flex flex-row gap-1 transition-opacity")}>
				<AddHologramToGoButton
					hologram={hologram}
					className="border border-solid border-white/0 transition-all hover:border-white/40 hover:bg-white/20 md:opacity-0 md:group-hover/card:opacity-100"
				/>

				<HologramCastButton
					hologram={hologram}
					playlistItemId={playlistItemID}
					fillColor={hologram.id === castHologram?.id ? "fill-green-400" : "fill-white"}
					className="hidden h-11 w-11 rounded-full border border-solid border-white/0 bg-white/0 p-3 transition-all backdrop:blur-none hover:border-white/40 hover:bg-white/20 hover:shadow-xl hover:backdrop-blur-lg md:block md:opacity-0 md:group-hover/card:opacity-100"
				/>

				<BanHologramButton
					id={id}
					className="bg-black bg-opacity-0 hover:bg-white hover:bg-opacity-20 hover:backdrop-blur-lg md:opacity-0 md:group-hover/card:opacity-100"
				/>
				<div className="hidden md:block">
					<HologramDropDown
						className="border border-solid border-white/0 bg-white/0 transition-all backdrop:blur-none hover:border-white/40 hover:bg-white/20 hover:backdrop-blur-lg md:opacity-0 md:group-hover/card:opacity-100"
						//@ts-ignore - TEMPORARY
						hologram={hologram}
						privacy={privacy}
						setPrivacy={setPrivacy}
						setDownloadPopup={setDownloadPopup}
						setFlagPopup={setFlagPopup}
						setSharePopup={setSharePopup}
						setDeletePopup={setDeletePopup}
						forceDarkMode={true}
					/>
				</div>
				<div className="flex h-11 w-11 items-center justify-center rounded-full border border-solid border-white/0 bg-white/0 transition-all hover:border-white/40 hover:bg-white/20 hover:shadow-xl hover:backdrop-blur-lg md:hidden md:opacity-0 md:group-hover/card:opacity-100">
					<button
						className="flex flex-row items-center justify-center gap-2 rounded-lg"
						onClick={(e) => {
							e.stopPropagation(), e.preventDefault(), setOpenSettings(!openSettings)
						}}>
						<EllipsisHorizontalIcon className="w-5 stroke-2 text-white drop-shadow-lg" />
					</button>
				</div>
			</div>
			{selectable && (
				<div
					className={twMerge(
						"absolute left-0.5 top-0.5 opacity-100 transition-opacity md:left-3 md:top-3",
						selected ? "md:opacity-100" : "md:opacity-0",
					)}>
					<CheckBox
						selected={selected}
						onClick={
							onSelected
								? (e) => {
										e.preventDefault()
										e.stopPropagation(), onSelected()
									}
								: () => {}
						}
					/>
				</div>
			)}
			{user && <PreviewCardFooter title={hologram.title ?? ""} user={user} />}

			{/* POPUPS */}
			{/* we don't want to mount the popups unless they're valid */}
			{sharePopup !== null && (
				<ShareHologramPopup
					hologram={sharePopup}
					popup={{ open: sharePopup !== null, onClose: onShareClose }}
				/>
			)}

			{downloadPopup !== null && (
				<DownloadPopup
					hologram={downloadPopup}
					popup={{ open: downloadPopup !== null, onClose: onDownloadClose }}
				/>
			)}

			{deletePopup !== null && (
				<DeleteHologramPopup ids={[hologram.id]} open={!!deletePopup} setOpen={setDeletePopup} />
			)}

			{flagPopup !== null && (
				<FlagHologramPopup hologram={flagPopup} open={!!flagPopup} setOpen={setFlagPopup} />
			)}
			{openSettings !== false && (
				<HologramSettingsPopup
					open={openSettings}
					setOpen={setOpenSettings}
					//@ts-ignore - TEMPORARY
					hologram={hologram}
					privacy={privacy}
					setPrivacy={setPrivacy}
					setFlagPopup={setFlagPopup}
					setDownloadPopup={setDownloadPopup}
					setSharePopup={setSharePopup}
					setDeletePopup={setDeletePopup}
				/>
			)}
		</div>
	)
}
